import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { employesUnderline } from 'images/koppelingen'
import { employes } from 'images'
import Layout from '../../../../layouts'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { tkp } from 'images/koppelingen'

const dummyKlantVerhaal = {
  pageTitle: '“Makkelijk contact en duidelijkheid voor iedereen”',
  pageStreamer: 'Marieke de Vos, Codaisseur',
  useCase: 'Founders',
  person: {
    name: 'Marieke',
    functionTitle: 'HR MANAGER, ebay',
    companyLogo: 'https://via.placeholder.com/129x23',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: 'https://via.placeholder.com/344x224/ff48df',
  },
  content: [{
    title: 'TKP als pensioenfonds voor detailhandel',
    description: 'Als werkgever in de detailhandel val je onder het bedrijfstakpensioenfonds, waarbij je pensioenaangifte doet bij uitvoerder TKP. Deelname hieraan is verplicht. Dit zorgt ervoor dat je als werkgever het pensioen van je werknemers moet onderbrengen bij TKP.'
        }, {
    title: 'Pensioenaangifte geautomatiseerd in Employes',
    description: 'Als ondernemer kom je waarschijnlijk al handen te kort en heb je hiernaast niet de tijd om je nog bezig te houden met ingewikkelde regelgevingen. Ook op het gebied van pensioenaangifte zorgt de software van Employes ervoor dat je hier geen omkijken meer naar hebt. Employes is volledig afgestemd op de regeling van TKP, waardoor altijd de juiste looncomponenten worden meegegeven in de pensioenaangifte. Employes levert deze gegevens automatisch aan bij je pensioensfonds. Zelf hoef je niks te doen.'
  }],
  otherClientStories: [{
    name: 'Jolanda',
    functionTitle: 'HR MANAGER, airbnb',
    companyLogo: 'https://via.placeholder.com/102x32/3E4F68/fff',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: 'https://via.placeholder.com/344x224',
    quote: 'Het werkt als een trein'
  }, {
    name: 'Peter',
    functionTitle: 'HR MANAGER, airbnb',
    companyLogo: 'https://via.placeholder.com/102x32/3E4F68/fff',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: 'https://via.placeholder.com/344x224',
    quote: 'Het werkt als een trein'
  }, {
    name: 'Johan',
    functionTitle: 'HR MANAGER, airbnb',
    companyLogo: 'https://via.placeholder.com/102x32/3E4F68/fff',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: 'https://via.placeholder.com/344x224',
    quote: 'Het werkt als een trein'
  }]
}

class Tkp extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

          <Helmet>
            <title>Koppeling TKP & Employes | Salarisadministratie</title>
            <meta name="description" content="Pensioenaangifte TKP geautomatiseerd in Employes."/>
            <meta itemprop="name" content="Koppeling TKP & Employes | Salarisadministratie| Pensioenaangifte" />
            <meta itemprop="description" content="Pensioenaangifte TKP geautomatiseerd in Employes."/>
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="koppeling tkp padding-xxl">
            <div className="flakes flakes--left"></div>
            <div className="flakes flakes--right"></div>

            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Pensioenaangifte via TKP <span className="hide-mobile"> voor de detailhandel</span></h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                    <img className="margin-s-bottom" src={employes} alt="logo employes"/>

                    <svg className="margin-s-bottom" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                      <path fill="#DAE6F0" fillRule="evenodd" d="M167,130 L198,130 L198,131 L167,131 L167,163 L165,163 L165,131 L134,131 L134,130 L165,130 L165,99 L167,99 L167,130 Z" opacity=".4" transform="translate(-134 -99)"/>
                    </svg>

                    <img src={tkp}/>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">
            <div>
                {dummyKlantVerhaal.content.map((section, idx) => {
                  const firstObjectKey = Object.keys(section)[0]
                  const isImage = (firstObjectKey === 'image')
                  const { title, description } = section

                  return (
                    <div className="section margin-m-bottom" key={idx}>
                      <div className={`container-${ isImage ? 'md image' : 'sm' }`} key={idx}>
                        <div className="grid yg center">
                          <div className={`col-12 ${isImage ? 'no-pad image-container' : 'padding-m'}`}>
                          {{
                            title: (
                              <div className="text">
                                <h4>{ title }</h4>
                                <p>{ description}</p>
                              </div>
                            ),
                            image: (
                              <img src={section.image}/>
                            )
                          }[firstObjectKey]}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/" className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

Tkp.propTypes = {
  data: PropTypes.object
}

export default Tkp
